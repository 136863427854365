import { DeliveryOption, Offer } from "@/types";

const getDayOfWeek = (dateString: string) => {
  // Split the input string into month, day, and year
  const [month, day, year] = dateString.split("/").map(Number);

  // Create a Date object (note: months are 0-indexed in JavaScript)
  const date = new Date(year, month - 1, day);

  // Array of day names
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Get the day of the week as a number (0 for Sunday, 1 for Monday, etc.)
  const dayOfWeekIndex = date.getDay();

  // Return the corresponding day name
  return daysOfWeek[dayOfWeekIndex];
};

const isSaturday = (value: string) => {
  const date = new Date(value);
  const day = date.getDay();
  return day === 6 ? true : false;
};

const nthNumber = (number: number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const getFormattedDateForDisplay = (date: Date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  return `${month} ${day}${nthNumber(day)}`;
};

const getEligibleOffers = (
  deliveryOption: number,
  date: Date,
  totalAmount: number,
  occasion: string,
  selectedAddons = [], // Default to empty array if undefined
  menuDataObj = { specialOffers: {} }, // Default to empty object if undefined
  selectedMenu: number
) => {
  const dayOfWeek = date.getDay();
  const month = date.getMonth();

  // Use default empty object if specialOffers is undefined or null
  const specialOffers = filterSpecialOffersBySelectedAddons(
    menuDataObj.specialOffers || {}, // Ensure it is an object
    selectedAddons || [] // Ensure it is an array
  );

  const isMatchingOccasion = (
    offerOccasions: any[],
    selectedOccasion: string
  ) =>
    offerOccasions.includes("all") || offerOccasions.includes(selectedOccasion);

  // Return an empty array if specialOffers is not an object
  return Object.entries(specialOffers)
    .filter(([_, offer]) => {
      console.log("key", _);
      const {
        isActive = false,
        occasions = [],
        deliveryOptions = [],
        offeredToMenus = [],
        offeredOnDays = [],
        offeredMonths = [],
        minimum = 0,
      } = offer as Offer;

      return (
        isActive &&
        isMatchingOccasion(occasions, occasion) &&
        deliveryOptions.includes(deliveryOption) &&
        offeredToMenus.includes(selectedMenu) &&
        offeredOnDays.includes(dayOfWeek) &&
        offeredMonths.includes(month) &&
        totalAmount >= minimum
      );
    })
    .map(([key]) => key);
};

const filterSpecialOffersBySelectedAddons = (
  specialOffers: Record<string, Offer> = {},
  selectedAddons = []
) => {
  // Convert selectedAddons to a flat array of selected item IDs
  const selectedAddonIds = selectedAddons.flatMap((addonGroup) =>
    Object.values(addonGroup).map((addon: any) => addon.itemId)
  );

  return Object.keys(specialOffers as any).reduce(
    (filteredOffers: any, offerKey: string) => {
      const offer: any = specialOffers[offerKey];

      // Default empty array if dontShowIfItemsSelected is undefined
      const dontShowIfItemsSelected = offer.dontShowIfItemsSelected || [];

      // Check if any selected add-ons are in the dontShowIfItemsSelected array
      const shouldShowOffer = !dontShowIfItemsSelected.some((addonId: number) =>
        selectedAddonIds.includes(addonId)
      );

      // If the offer should be shown, add it to the filtered offers
      if (shouldShowOffer) {
        filteredOffers[offerKey] = offer as Offer;
      }

      return filteredOffers;
    },
    {}
  );
};

export {
  getDayOfWeek,
  isSaturday,
  nthNumber,
  getFormattedDateForDisplay,
  filterSpecialOffersBySelectedAddons,
  getEligibleOffers,
};
