import {
  DELIVERY_OPTIONS,
  MENUS,
  ALL_MONTHS,
  MONTHS as M,
  DAYS as D,
} from "./index";
import { Offer } from "@/types";

export const OFFERS_DROPOFF: Record<string, Offer> = {
  deliveryHolidayFreeMenu1Upgrade: {
    title: "FREE Menu Upgrade to Menu 2",
    description:
      "If you place an order similar to this quote, you qualify for FREE Menu Upgrade!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_1],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: true,
  },
  deliveryHolidayFreeMenu2Upgrade: {
    title: "FREE Menu Upgrade to Menu 3",
    description:
      "If you place an order similar to this quote, you qualify for FREE Menu Upgrade!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_2],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: true,
  },
  deliveryHolidayMenu3AquaFresca: {
    title: "Complimentary Aqua Fresca",
    description:
      "If you place an order similar to this quote, you qualify for complimentary Aqua Fresca!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_3],
    occasions: ["all"],
    dontShowIfItemsSelected: [210],
    isSelectable: true,
  },
  deliveryGuacAndChips: {
    title: "Complimentary Guac & Chips",
    description:
      "If you place an order similar to this quote, you qualify for complimentary Chunky Guacamole & Chips!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_4],
    occasions: ["all"],
    dontShowIfItemsSelected: [102, 600],
    isSelectable: true,
  },
  deliveryChurros: {
    title: "Complimentary Churros",
    description:
      "If you place an order similar to this quote, you qualify for complimentary Churros!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_4],
    occasions: ["all"],
    dontShowIfItemsSelected: [700, 704],
    isSelectable: true,
  },
  deliveryHolidayComplimentaryPicoDeGalloTacoBar: {
    title: "Complimentary Pico de Gallo (Taco Bar)",
    description:
      "If you place an order similar to this quote, you qualify for complimentary Pico de Gallo (Taco Bar)!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [D.SUNDAY, D.MONDAY, D.TUESDAY, D.WEDNESDAY],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_4],
    occasions: ["all"],
    dontShowIfItemsSelected: [103, 610],
    isSelectable: true,
  },
  deliveryHolidayComplimentaryQuesadillas: {
    title: "Complimentary Quesadillas",
    description:
      "If you place an order similar to this quote, you qualify for complimentary Quesadillas!",
    minimum: 500,
    isActive: true,
    offeredOnDays: [D.SUNDAY, D.MONDAY, D.TUESDAY, D.WEDNESDAY],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.DROP_OFF, DELIVERY_OPTIONS.PICK_UP],
    offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3, MENUS.MENU_4],
    occasions: ["all"],
    dontShowIfItemsSelected: [112, 113, 114, 605],
    isSelectable: true,
  },
};
