import { createStore } from "vuex"; // Updated import
import QuoteService from "@/services/QuoteService";
import { isSaturday, getDayOfWeek } from "../utils/sharedUtils";

import menuData from "./menu-data";
import smartFeaturesData from "./smart-features-data";

import { AddOn, MenuData, State, DeliveryOptionKey, Item } from "@/types";

export default createStore({
  state: {
    currentStep: 0,
    maxSteps: 6,
    showChafersModal: false,
    showDeliveryPreviewModal: false,
    deliveryPreview: "onsite",
    showAddonPreviewModal: false,
    addonPreview: null,
    changeStepAllowed: true,
    customerData: {
      // name: "Vladislav",
      // lastName: "Stankovic",
      // email: "vlad@testingtest23aaa.com",
      // phone: "123453",
      // city: "New York",
      // date: "2022-06-28",
      // time: "19:57",
      // hours: "01",
      // minutes: "00",
      // amPm: "am",
      // occasion: "Birthday",
      // additionalMessage: "Some sample message",
      name: "",
      lastName: "",
      email: "",
      phone: "",
      city: "",
      date: "",
      time: "",
      hours: "",
      minutes: "",
      dayOfTheWeek: "",
      amPm: "",
      occasion: "",
      additionalMessage: "",
      contactMe: "",
      complimentaryChaffers: false,
      specialOffers: undefined,
    },
    menuDataObj: {} as MenuData,
    smartFeaturesDataObj: {},
    numberOfGuests: 15,
    salesTax: 0.0875,
    minGuestsReceivingChaffers: 25,
    minGuests: 10,
    maxGuests: 700,
    minQuantity: 1,
    maxQuantity: 100,
    onsiteMinimumAmount: 1000,
    onsiteLowerMinimumAmount: 800,
    deliveryMinimum: 175,
    minimumMet: false,
    showMinimumNotMet: false,
    shortAmount: 0 as number,
    currentMinimum: 0 as number,
    isSaturday: false as boolean,
    defaultQuantityStep: 5 as number,
    defaultInitialQuantity: 1 as number,
    selectedMenu: 0 as number,
    selectedDelivery: 0 as number,
    selectedAddons: {},
    selectedProteins: [] as number[],
    selectedSpecialOffer: null,
    complimentaryTasting: false,
    complimentaryChurros: false,
    salesTaxTotal: 0,
    menuCostSum: 0,
    totalCost: 0,
    proteinsError: false,
    proteinsValid: false,
  } as State,
  mutations: {
    SET_MENU_DATA(state, data) {
      state.menuDataObj = data;
      state.selectedAddons = data.addOns;
    },
    SET_SMART_FEATURES_DATA(state, data) {
      state.smartFeaturesDataObj = data;
    },
    SET_INITIAL_STATE(state, addOnsObj) {
      state.selectedMenu = 0;
      state.selectedDelivery = 0;
      state.selectedAddons = addOnsObj;
    },
    SET_NUMBER_OF_GUESTS(state, data) {
      state.numberOfGuests = data;
    },
    SET_DELIVERY_OPTION(state, data) {
      state.selectedDelivery = data;
    },
    SET_SELECTED_MENU(state, data) {
      state.selectedMenu = data;
    },
    SET_PROTEINS_ERROR(state, value) {
      state.proteinsError = value;
    },
    SET_PROTEINS_VALID(state, data) {
      state.proteinsValid = data;
    },
    SET_SELECTED_OFFER(state, data) {
      state.selectedSpecialOffer = data;
    },
    SEND_QUOTE(state, data) {
      QuoteService.sendQuoteRequest(data);
    },
    ADD_SELECTED_ADDON(state, addonData) {
      state.selectedAddons[`${addonData.groupId}`][`${addonData.itemId}`] =
        addonData;
    },
    REMOVE_ADDON(state, addonData) {
      delete state.selectedAddons[`${addonData.groupId}`][
        `${addonData.itemId}`
      ];
    },
    UPDATE_ITEM_QUANTITY(state, addonData) {
      state.selectedAddons[`${addonData.groupId}`][
        `${addonData.itemId}`
      ].quantity = addonData.quantity;
    },
    UPDATE_CURRENT_STEP(state, number) {
      state.currentStep = number;
    },
    UPDATE_STEP_CHANGE(state, value) {
      state.changeStepAllowed = value;
    },
    UPDATE_CUSTOMER_DATA(state, customerData) {
      state.customerData = customerData;
    },
    UPDATE_DAY_OF_WEEK(state, dayName) {
      state.customerData.dayOfTheWeek = dayName;
    },
    UPDATE_EVENT_DATE(state, date) {
      state.customerData.date = date;
      state.isSaturday = isSaturday(date);
    },
    SET_CONTACT_ME(state, value) {
      state.customerData.contactMe = value;
    },
    SET_COMPL_CHAFFERS(state, value) {
      state.customerData.complimentaryChaffers = value;
    },
    SET_COMPL_TASTING(state, value) {
      state.complimentaryTasting = value;
    },
    SET_COMPL_CHURROS(state, value) {
      state.complimentaryChurros = value;
    },
    UPDATE_PROTEINS_LIST(state, proteinsData) {
      state.selectedProteins = proteinsData;
    },
    UPDATE_SPECIAL_OFFERS(state, specialOffersData) {
      state.customerData.specialOffers = specialOffersData;
    },
    ADD_SELECTED_PROTEIN(state: State, item) {
      // const newProteinList: number[] = state.selectedProteins.push(item);
      // state.selectedProteins = newProteinList;
    },
    REMOVE_SELECTED_PROTEIN(state, item) {
      // const index = state.selectedProteins.indexOf(item);
      // if (index < 0) {
      //   return;
      // }
      // state.selectedProteins.splice(index, 1);
    },
    REMOVE_FIRST_PROTEIN(state) {
      // const newProteinList = state.selectedProteins.shift();
      // state.selectedProteins = newProteinList;
    },
    SET_MENU_COST_SUM(state, number) {
      // menu cost without tax
      state.menuCostSum = number;
    },
    SET_TOTAL_COST(state, number) {
      state.totalCost = number;
    },
    SET_SALES_TAX_TOTAL(state, number) {
      state.salesTaxTotal = number;
    },
    SET_MINIMUM_MET(state, value) {
      state.minimumMet = value;
    },
    TOGGLE_CHAFERS_MODAL(state, value) {
      state.showChafersModal = value;
    },
    TOGGLE_DELIVERY_PREVIEW_MODAL(state, value) {
      state.showDeliveryPreviewModal = value;
    },
    SET_DELIVERY_PREVIEW_OPTION(state, value) {
      state.deliveryPreview = value;
    },
    TOGGLE_ADDON_PREVIEW_MODAL(state, value) {
      state.showAddonPreviewModal = value;
    },
    SET_ADDON_PREVIEW_OPTION(state, value) {
      state.addonPreview = value;
    },
    TOGGLE_SHOW_MINIMUM_NOT_MET(state, value) {
      state.showMinimumNotMet = value;
    },
    SET_SHORT_AMOUNT(state, value) {
      state.shortAmount = value;
    },
    SET_CURRENT_MINIMUM(state, value) {
      state.currentMinimum = value;
    },
    SET_CUSTOMER_FORM_VALIDITY(state, value) {
      state.customerFormValidity = value;
    },
  },
  actions: {
    nextStepClicked({ dispatch }) {
      if (this.state.currentStep === 0) {
        // Dispatch an action to validate the form
        dispatch("validateCustomerInfoForm");
        return;
      }

      if (this.state.currentStep === 2) {
        // Dispatch an action to validate proteins
        dispatch("validateProteinOptions");
        if (!this.state.proteinsValid) {
          dispatch("setProteinsError", true);
          return;
        }
      }

      if (this.state.currentStep === 4) {
        // Calculate total and handle minimums
        dispatch("calculateTotal");
        dispatch("handleMinimums");
        if (!this.state.minimumMet) {
          return;
        }
      }

      const newStep = this.state.currentStep + 1;

      if (newStep > this.state.maxSteps) {
        return;
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
      // Dispatch an action to update the step
      dispatch("setNewStep", newStep);
    },
    validateCustomerInfoForm({ commit, dispatch }) {
      const form = document?.getElementById("customerForm") as HTMLFormElement;
      const isValid: boolean = form.reportValidity();

      if (isValid) {
        commit("SET_CUSTOMER_FORM_VALIDITY", true);
        dispatch("setNewStep", 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        commit("SET_CUSTOMER_FORM_VALIDITY", false);
      }
    },
    setProteinsError({ commit }, value) {
      commit("SET_PROTEINS_ERROR", value);
    },
    validateProteinOptions({ commit }) {
      // Perform validation logic for proteins
      const isValid =
        this.state.selectedProteins.length ===
        menuData.menus[this.state.selectedMenu].chooseFrom.choiceOf;
      commit("SET_PROTEINS_VALID", isValid);
    },
    setMenuData({ commit }) {
      commit("SET_MENU_DATA", menuData);
      commit("SET_SMART_FEATURES_DATA", smartFeaturesData);
      Promise.resolve(menuData).then((res) => {
        const addOns = res.addOns;
        const tempAddOns: any = {};
        addOns.forEach((element: AddOn) => {
          tempAddOns[`${element.id as number}`] = {};
        });
        commit("SET_INITIAL_STATE", tempAddOns);
      });
    },
    setNumberOfGuests({ commit }, number) {
      commit("SET_NUMBER_OF_GUESTS", number);
    },
    setDeliveryOption({ commit }, number) {
      commit("SET_DELIVERY_OPTION", number);
    },
    setSelectedMenu({ commit }, number) {
      commit("SET_SELECTED_MENU", number);
    },
    setSelectedOffer({ commit }, offerName) {
      commit("SET_SELECTED_OFFER", offerName);
    },
    addSelectedAddon({ commit }, addonData) {
      commit("ADD_SELECTED_ADDON", addonData);
    },
    removeAddon({ commit }, addonData) {
      commit("REMOVE_ADDON", addonData);
    },
    updateItemQuantity({ commit }, addonQuantityData) {
      commit("UPDATE_ITEM_QUANTITY", addonQuantityData);
    },
    setNewStep({ commit }, number) {
      commit("UPDATE_CURRENT_STEP", number);
    },
    setStepChange({ commit }, value) {
      commit("UPDATE_STEP_CHANGE", value);
    },
    setCustomerData({ commit }, customerData) {
      commit("UPDATE_CUSTOMER_DATA", customerData);
    },
    setEventDate({ commit }, date) {
      const dayOfTheWeek = getDayOfWeek(date);
      console.log("DDDDDD >>>>", dayOfTheWeek);

      commit("UPDATE_DAY_OF_WEEK", dayOfTheWeek);
      commit("UPDATE_EVENT_DATE", date);
    },
    removeFirstProteinItem({ commit }) {
      commit("REMOVE_FIRST_PROTEIN");
    },
    updateProteinsList({ commit, dispatch }, data) {
      commit("UPDATE_PROTEINS_LIST", data);
      dispatch("validateProteinOptions");
      if (this.state.proteinsValid) {
        dispatch("setProteinsError", false);
      }
    },
    removeSelectedProteinItem({ commit }, item) {
      commit("REMOVE_SELECTED_PROTEIN", item);
    },
    updateSpecialOffers({ commit }, offersData) {
      commit("UPDATE_SPECIAL_OFFERS", offersData);
    },
    addSelectedProteinItem({ commit }, item) {
      commit("ADD_SELECTED_PROTEIN", item);
    },
    setMenuCostSum({ commit }, number) {
      // setting menu cost without tax
      commit("SET_MENU_COST_SUM", number);
    },
    setQuoteTotal({ commit }, number) {
      commit("SET_TOTAL_COST", number);
    },
    setCurrentShortAmount({ commit }, value) {
      commit("SET_SHORT_AMOUNT", value);
    },
    setCurrentMinimum({ commit }, value) {
      commit("SET_CURRENT_MINIMUM", value);
    },
    setMinimumMet({ commit }, value) {
      commit("SET_MINIMUM_MET", value);
    },
    setSalesTaxTotal({ commit }, number) {
      commit("SET_SALES_TAX_TOTAL", number);
    },
    setContactMe({ commit }, value) {
      commit("SET_CONTACT_ME", value);
    },
    setComplimentaryChaffers({ commit }, value) {
      commit("SET_COMPL_CHAFFERS", value);
    },
    setComplimentaryTasting({ commit }, value) {
      commit("SET_COMPL_TASTING", value);
    },
    setComplimentaryChurros({ commit, dispatch }, value) {
      commit("SET_COMPL_CHURROS", value);
      if (value && this.state.selectedSpecialOffer == "Complimentary Churros") {
        dispatch("setSelectedOffer", null);
      }
    },
    sendQuote({ commit }, data) {
      console.log("Sending the calculated stuff", data);
      commit("SEND_QUOTE", data);
    },
    calculateTotal({ dispatch }) {
      const categoryIds = Object.keys(this.state.selectedAddons);
      let menuCostSum =
        this.state.numberOfGuests *
        this.state.menuDataObj.menus[this.state.selectedMenu].deliveryOption[
          this.state.selectedDelivery
        ].price;

      categoryIds.forEach((element) => {
        if (Object.keys(this.state.selectedAddons[element]).length > 0) {
          const itemIds = Object.keys(this.state.selectedAddons[element]);

          itemIds.forEach((item) => {
            const tempItem = this.state.selectedAddons[element][item];
            menuCostSum += tempItem.price * tempItem.quantity;
          });
        }
      });

      const salesTaxAmount =
        parseFloat(menuCostSum.toFixed(2)) * this.state.salesTax;
      const total = menuCostSum + salesTaxAmount;

      dispatch("setSalesTaxTotal", parseFloat(salesTaxAmount.toFixed(2)));
      dispatch("setQuoteTotal", parseFloat(total.toFixed(2)));
      dispatch(
        "setMinimumMet",
        parseFloat(total.toFixed(2)) > this.state.onsiteMinimumAmount
      );
      dispatch("setMenuCostSum", parseFloat(menuCostSum.toFixed(2)));
    },
    toggleChafersModal({ commit }, value: boolean) {
      commit("TOGGLE_CHAFERS_MODAL", value);
    },
    toggleDeliveryPreviewModal({ commit }, value: boolean) {
      commit("TOGGLE_DELIVERY_PREVIEW_MODAL", value);
    },
    setDeliveryPreviewOption({ commit }, option: DeliveryOptionKey) {
      commit("SET_DELIVERY_PREVIEW_OPTION", option);
    },
    toggleAddonPreviewModal({ commit }, data: { show: boolean; item: Item }) {
      commit("TOGGLE_ADDON_PREVIEW_MODAL", data.show);
      if (data.item.id) {
        commit("SET_ADDON_PREVIEW_OPTION", data.item);
      }
    },
    toggleShowMinimumNotMet({ commit }, value) {
      commit("TOGGLE_SHOW_MINIMUM_NOT_MET", value);
    },
    handleMinimums({ getters, dispatch }) {
      let currentMinimumAmount = 0;
      if (this.state.selectedDelivery === 2) {
        currentMinimumAmount = getters.getCurrentOnsiteMinimum;
      } else {
        currentMinimumAmount = this.state.deliveryMinimum;
      }
      dispatch("setCurrentMinimum", currentMinimumAmount);

      const currentShortAmount = Number(
        (currentMinimumAmount - this.state.menuCostSum).toFixed(2)
      );
      dispatch("setCurrentShortAmount", currentShortAmount);
      if (currentShortAmount > 0) {
        // tell user how much more to add to order
        dispatch("setMinimumMet", false);
        dispatch("toggleShowMinimumNotMet", true);
        return;
      } else {
        dispatch("setMinimumMet", true);
        dispatch("toggleShowMinimumNotMet", false);
      }
    },
  },
  getters: {
    selectedAddons: (state) => state.selectedAddons,
    getCurrentOnsiteMinimum: (state) => {
      return state.isSaturday
        ? state.onsiteMinimumAmount
        : state.onsiteLowerMinimumAmount;
    },
  },
  modules: {},
});
