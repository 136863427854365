import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

import { onMounted } from 'vue';
import { useStore } from 'vuex';
import Hotjar from '@hotjar/browser';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useStore();

const setMenuData = () => {
  store.dispatch('setMenuData');
};

onMounted(() => {
  setMenuData();
  const siteId = process.env.VUE_APP_HOTJAR_ID ?? undefined;
  const hotjarVersion = 6;

  if (siteId) {
    Hotjar.init(Number(siteId), hotjarVersion);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view)
  ]))
}
}

})