<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import Hotjar from '@hotjar/browser';

const store = useStore();

const setMenuData = () => {
  store.dispatch('setMenuData');
};

onMounted(() => {
  setMenuData();
  const siteId = process.env.VUE_APP_HOTJAR_ID ?? undefined;
  const hotjarVersion = 6;

  if (siteId) {
    Hotjar.init(Number(siteId), hotjarVersion);
  }
});
</script>

<style lang="scss">
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");
@import '@/assets/scss/variables.scss';

@mixin gradient-bg {
  background: rgb(28, 96, 223);
  background: linear-gradient(160deg, rgb(28, 96, 223) 0%, rgba(75,0,130,1) 100%);
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

.bg-gradient {
  @include gradient-bg;
}

.bg-image {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

hr {
  border-top: 1px solid $border-color;
  margin: 12px 0;
}
</style>
