import {
  DELIVERY_OPTIONS,
  MENUS,
  DAYS as D,
  MONTHS as M,
  ALL_MONTHS,
  ALL_MENUS,
} from "./index";

import { Offer } from "@/types";

export const OFFERS_ONSITE: Record<string, Offer> = {
  tastingSaturday: {
    title: "Complimentary tasting",
    description:
      "This quote qualifies for complimentary tasting! We will send you a link to schedule your tasting session.",
    minimum: 1900,
    isActive: true,
    offeredOnDays: [D.FRIDAY, D.SATURDAY],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
    offeredToMenus: [...ALL_MENUS],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: false,
  },
  tastingOffers: {
    title: "Complimentary tasting",
    description:
      "This quote qualifies for complimentary tasting! We will send you a link to schedule your tasting session.",
    minimum: 1500,
    isActive: true,
    offeredOnDays: [D.SUNDAY, D.MONDAY, D.TUESDAY, D.WEDNESDAY, D.THURSDAY],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
    offeredToMenus: [...ALL_MENUS],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: false,
  },
  churros: {
    title: "Complimentary Churros",
    description:
      "If you place an order similar to this quote, you qualify for complimentary Churros!",
    minimum: 3200,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
    offeredToMenus: [...ALL_MENUS],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: true,
  },
  churrosSaturday: {
    title: "Complimentary Churros",
    description:
      "If you place an order similar to this quote, you qualify for complimentary Churros!",
    minimum: 3200,
    isActive: true,
    offeredOnDays: [D.SATURDAY],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
    offeredToMenus: [...ALL_MENUS],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: false,
  },
  onsiteHolidayDiscount150: {
    title: "$150.00 off",
    description:
      "If you place an order similar to this quote, you qualify for $150.00 off!",
    minimum: 1100,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
    offeredToMenus: [MENUS.MENU_1, MENUS.MENU_2, MENUS.MENU_3],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: true,
  },
  onsiteHolidayDiscount200: {
    title: "$200.00 off",
    description:
      "If you place an order similar to this quote, you qualify for $200.00 off!",
    minimum: 2200,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
    offeredToMenus: [MENUS.MENU_4],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: true,
  },
  onsiteHolidayFreeMenu1Upgrade: {
    title: "FREE Menu Upgrade to Menu 2",
    description:
      "If you place an order similar to this quote, you qualify for FREE Menu Upgrade!",
    minimum: 1500,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
    offeredToMenus: [MENUS.MENU_1],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: true,
  },
  onsiteHolidayFreeMenu2Upgrade: {
    title: "FREE Menu Upgrade to Menu 3",
    description:
      "If you place an order similar to this quote, you qualify for FREE Menu Upgrade!",
    minimum: 1500,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
    offeredToMenus: [MENUS.MENU_2],
    occasions: ["all"],
    dontShowIfItemsSelected: [],
    isSelectable: true,
  },
  complimentaryGuacAndChips: {
    title: "Complimentary Guac & Chips",
    description:
      "If you place an order similar to this quote, you qualify for Complimentary Guac & Chips!",
    minimum: 1500,
    isActive: true,
    offeredOnDays: [
      D.SUNDAY,
      D.MONDAY,
      D.TUESDAY,
      D.WEDNESDAY,
      D.THURSDAY,
      D.FRIDAY,
    ],
    offeredMonths: [...ALL_MONTHS],
    deliveryOptions: [DELIVERY_OPTIONS.ON_SITE],
    offeredToMenus: [MENUS.MENU_3],
    occasions: ["all"],
    dontShowIfItemsSelected: [102, 600],
    isSelectable: true,
  },
};
